import clsx from "clsx";
import { InputSize } from "./Input.type";

export const INPUT_BASE_SIZE_STYLE_MAP: Record<InputSize, string> = {
  big: clsx("c16-regular px-[16px] py-[12px]"),
  small: clsx("c14-regular px-[14px] py-[11px]"),
};

export const INPUT_SIZE_STYLE_MAP: Record<InputSize, string> = {
  big: clsx("h-[46px]"),
  small: clsx("h-[42px]"),
};

export const INPUT_READ_ONLY_SIZE_STYLE_MAP: Record<InputSize, string> = {
  big: clsx("min-h-[46px] shadow-none"),
  small: clsx("min-h-[42px] shadow-none"),
};

export const INPUT_ERROR_STYLE = clsx(
  "shadow-context-error hover:shadow-context-error focus:!shadow-context-error",
);

export const INPUT_HOVER_STYLE = clsx("hover:shadow-border-hover");

export const INPUT_FOCUSED_STYLE = clsx(
  "focus-within:!shadow-background-brand-default focus-within:!outline-none",
);

export const INPUT_DISABLED_STYLE = clsx(
  "bg-background-input-disabled shadow-border-disabled text-text-disabled",
);

export const INPUT_VIEW_SIZE_STYLE_MAP: Record<InputSize, string> = {
  big: clsx("min-h-[46px] shadow-none"),
  small: clsx("min-h-[42px] shadow-none"),
};
