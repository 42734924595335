export enum StatusCode {
  Success = "ok",
  LoginFail = "CTE000",
  AlreadySignUpWithSocialLogin = "CTE001",
  AlreadySignUpWithEmail = "CTE002",
  GoogleApiError = "CTE003",
  AbnormalAccess = "CTE004",
  EmailAlreadyExist = "CTE005",
  EmailNotExist = "CTE006",
  EmailAuthTimeExceed = "CTE007",
  ValidationFail = "CTE008",
  AuthenticateInfoNotExist = "CTE009",
  UserNotFound = "CTE010",
  WrongPassword = "CTE011",
  Duplicate = "CTE013",

  // 구글
  TokenAuthFail = "BAE000",
  TokenExpired = "BAE001",

  UnknownFileError = "FPE002",

  MaxSpaceCountExceeded = "SPE000",
  AlreadyShared = "SPE002",
  AlreadyAccepted = "SPE003",
}

export const STATUS_CODE_ALERT_MESSAGE_MAP: {
  [key in StatusCode]?: { title?: string; description?: string };
} = {
  [StatusCode.LoginFail]: {
    title: "일치하는 정보가 없습니다.",
  },
  [StatusCode.AlreadySignUpWithSocialLogin]: {
    title: "SNS 계정으로 가입된 이메일입니다.",
  },
  [StatusCode.AlreadySignUpWithEmail]: {
    title: "간편 회원가입으로\n가입된 이메일입니다.",
  },
  [StatusCode.GoogleApiError]: {
    title: "Google 계정 연동 중 오류가 발생했습니다.",
  },
  [StatusCode.AbnormalAccess]: {
    title: "비정상적인 접근입니다.\n관리자에게 문의해주세요.",
  },
  [StatusCode.EmailAlreadyExist]: {
    title: "간편 회원가입으로\n가입된 이메일입니다.",
  },
  [StatusCode.EmailNotExist]: {
    title: "등록되지 않은 이메일 주소입니다.",
    description: "회원 가입 후 월간햇빛바람을 이용해주세요.",
  },
  [StatusCode.EmailAuthTimeExceed]: {
    title: "이메일 인증시간이 만료되었습니다.",
  },
  [StatusCode.ValidationFail]: {
    title: "전송값 검증에 실패했습니다.\n관리자에게 문의해주세요.",
  },
  [StatusCode.AuthenticateInfoNotExist]: {
    title: "인증정보가 존재하지 않습니다.",
  },
  [StatusCode.TokenAuthFail]: {
    title: "구글 인증에 실패했습니다.",
  },
  [StatusCode.TokenExpired]: {
    title: "구글 인증이 만료되었습니다.",
  },
};
