"use client";

import { forwardRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { customTwMerge } from "../../../utils/customTwMerge";
import { Icon } from "../Icon/Icon";
import { designSystemColors } from "../../../styles/colors";
import { InputProps } from "./Input.type";
import {
  INPUT_BASE_SIZE_STYLE_MAP,
  INPUT_DISABLED_STYLE,
  INPUT_ERROR_STYLE,
  INPUT_FOCUSED_STYLE,
  INPUT_HOVER_STYLE,
  INPUT_READ_ONLY_SIZE_STYLE_MAP,
  INPUT_SIZE_STYLE_MAP,
  INPUT_VIEW_SIZE_STYLE_MAP,
} from "./Input.style";
import "../../../styles/index.css";
import clsx from "clsx";

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    type = "text",
    value,
    size,
    unit,
    guide,
    validCheckList,
    isSuccess,
    successMessage,
    isError,
    errorMessage,
    className,
    maxLength = 100,
    disabled,
    readOnly,
    isView,
    hasPasswordVisibleIcon,
    ...restProps
  },
  ref,
) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const isShowValidCheckList = !!validCheckList && validCheckList.length > 0;

  if (readOnly || isView) {
    return (
      <div className={className}>
        <div
          className={customTwMerge(
            "flex items-center justify-between break-words rounded-[6px] border-none shadow-[inset_0_0_0_1px] hover:border-none focus:border-none",
            INPUT_BASE_SIZE_STYLE_MAP[size],
            readOnly &&
              clsx(
                INPUT_READ_ONLY_SIZE_STYLE_MAP[size],
                "bg-background-input-readonly shadow-none",
              ),
            isView && INPUT_VIEW_SIZE_STYLE_MAP[size],
          )}
        >
          {!!value && <span className="break-all">{value}</span>}
          {!!unit && <span className="text-text-subtle ml-[4px]">{unit}</span>}
        </div>
        {!!guide && readOnly && (
          <div
            className={twMerge(
              "c12-regular text-text-guide mt-[5px]",
              disabled && "!text-text-disabled",
            )}
          >
            {guide}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={className}>
      <div
        className={customTwMerge(
          "bg-background-input-default shadow-border-default relative flex w-full justify-between rounded-[6px] shadow-[inset_0_0_0_1px]",
          INPUT_BASE_SIZE_STYLE_MAP[size],
          INPUT_SIZE_STYLE_MAP[size],
          !isError && INPUT_FOCUSED_STYLE,
          isError && INPUT_ERROR_STYLE,
          !disabled && INPUT_HOVER_STYLE,
          disabled && INPUT_DISABLED_STYLE,
        )}
      >
        <input
          className={twMerge(
            "placeholder:text-text-subtler w-full truncate outline-none",
            unit && "pr-[16px]",
            disabled && "placeholder:text-text-disabled",
          )}
          type={
            type === "password"
              ? isPasswordVisible
                ? "text"
                : "password"
              : type
          }
          value={value}
          ref={ref}
          disabled={disabled}
          maxLength={maxLength}
          readOnly={readOnly}
          style={{ fontFeatureSettings: '"case"' }}
          {...restProps}
        />
        {!!unit && type !== "password" && (
          <span
            className={twMerge(
              "c16-regular text-text-subtle whitespace-pre",
              disabled && "text-text-disabled",
            )}
          >
            {unit}
          </span>
        )}
        {type === "password" && isPasswordVisible && hasPasswordVisibleIcon && (
          <Icon
            name="visibility"
            size={20}
            className="fill-icon-subtler cursor-pointer"
            onClick={() => setIsPasswordVisible(false)}
          />
        )}
        {type === "password" &&
          !isPasswordVisible &&
          hasPasswordVisibleIcon && (
            <Icon
              name="visibility-off"
              size={20}
              className="fill-icon-subtler cursor-pointer"
              onClick={() => setIsPasswordVisible(true)}
            />
          )}
      </div>

      {!!guide && (
        <div
          className={twMerge(
            "c12-regular text-text-guide mt-[5px]",
            disabled && "!text-text-disabled",
          )}
        >
          {guide}
        </div>
      )}

      {!!isSuccess && !isShowValidCheckList && (
        <div className={twMerge("c12-regular text-context-success mt-[5px]")}>
          {successMessage}
        </div>
      )}

      {!!isError && !!errorMessage && (
        <div
          className={twMerge(
            "c12-regular text-context-error mt-[5px] break-words",
            (!!guide || isShowValidCheckList) && "mt-[3px]",
          )}
        >
          {errorMessage}
        </div>
      )}
      {isShowValidCheckList && !disabled && (
        <div
          className={twMerge(
            "c12-regular text-text-guide mt-[5px] flex flex-wrap gap-x-[12px]",
          )}
        >
          {validCheckList.map(({ text, valid }) => (
            <div key={text} className="flex items-center gap-[4px]">
              <span
                className={twMerge(
                  !valid && "text-text-guide",
                  (valid || isSuccess) && "!text-context-success",
                  disabled && "!text-text-disabled",
                )}
              >
                {text}
              </span>
              <Icon
                name="icon-check"
                size={14}
                fill={
                  disabled
                    ? designSystemColors["background-input-disabled"]
                    : valid || isSuccess
                    ? designSystemColors["context-success"]
                    : designSystemColors["text-guide"]
                }
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
});
