import { createPortal } from "react-dom";
import clsx from "clsx";
import { Button } from "60hz-design-system";
import { AlertProps } from "./Alert.type";

export function Alert({
  className,
  titleClassName,
  icon,
  isOpen,
  title,
  description,
  hasCancelButton = true,
  hasConfirmButton,
  cancelButtonText = "닫기",
  confirmButtonText = "확인",
  onClickCancel,
  onClickConfirm,
}: AlertProps) {
  if (!isOpen) {
    return null;
  }

  return createPortal(
    <div className="bg-background-neutral-disabled fixed left-0 top-0 z-[1000] flex h-full w-full items-center justify-center">
      <div
        className={clsx(
          "bg-background-neutral-default w-[320px] rounded-[10px]",
          className,
        )}
      >
        {icon && <div className="mt-[24px] flex justify-center">{icon}</div>}
        <div
          className={clsx(
            "h18-semibold text-text-default whitespace-pre-wrap px-[32px] py-[32px] pb-[4px] text-center",
            titleClassName,
            icon && "pt-[8px]",
          )}
        >
          {title}
        </div>
        {!!description && (
          <div className="c14-regular text-text-default px-[32px] text-center">
            {description}
          </div>
        )}
        <div className="flex gap-[8px] px-[32px] pb-[32px] pt-[16px]">
          {hasCancelButton && (
            <Button
              appearance={hasConfirmButton ? "outlined" : "default"}
              onClick={onClickCancel}
              size="small"
              className="flex-1"
            >
              {cancelButtonText}
            </Button>
          )}
          {hasConfirmButton && (
            <Button
              appearance="primary"
              className="flex-1"
              onClick={onClickConfirm}
              size="small"
            >
              {confirmButtonText}
            </Button>
          )}
        </div>
      </div>
    </div>,
    document.body,
  );
}
