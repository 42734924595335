"use client";

import { useModal } from "react-modal-hook";
import { useCallback, useEffect, useState } from "react";
import { Alert } from "./Alert";
import type { AlertProps } from "./Alert.type";

let activeAlertCount = 0;

interface UseAlertParams
  extends Omit<AlertProps, "isOpen" | "onClickConfirm" | "onClickCancel"> {
  onClickConfirm?: (args?: any) => void;
  onClickCancel?: (args?: any) => void;
}

export function useAlert(originAlertProps: UseAlertParams): {
  showAlert: (params?: { props?: UseAlertParams; args?: any }) => void;
  hideAlert: () => void;
} {
  const [alertArgs, setAlertArgs] = useState<any>();
  const [dynamicAlertProps, setDynamicAlertProps] = useState<UseAlertParams>();
  const [isOpen, setIsOpen] = useState(false);

  const showAlert = useCallback(
    (params?: { props?: UseAlertParams; args?: any }) => {
      const { props, args } = params ?? {};
      setDynamicAlertProps(props);
      setAlertArgs(args);
      setIsOpen(true);
      activeAlertCount++;
    },
    [],
  );

  const hideAlert = useCallback(() => {
    setIsOpen(false);
    setAlertArgs(undefined);
    setDynamicAlertProps(undefined);
    activeAlertCount--;
  }, []);

  const onClickConfirm = useCallback(() => {
    if (dynamicAlertProps?.onClickConfirm) {
      dynamicAlertProps.onClickConfirm(alertArgs);
    } else if (originAlertProps.onClickConfirm) {
      originAlertProps.onClickConfirm(alertArgs);
    } else {
      hideAlert();
    }
  }, [originAlertProps, dynamicAlertProps, alertArgs, hideAlert]);

  const onClickCancel = useCallback(() => {
    if (dynamicAlertProps?.onClickCancel) {
      dynamicAlertProps.onClickCancel(alertArgs);
    } else {
      originAlertProps.onClickCancel?.(alertArgs);
    }
    hideAlert();
  }, [originAlertProps, dynamicAlertProps, alertArgs, hideAlert]);

  const [showModal, hideModal] = useModal(
    () => (
      <Alert
        {...originAlertProps}
        {...dynamicAlertProps}
        onClickConfirm={onClickConfirm}
        onClickCancel={onClickCancel}
        isOpen={isOpen}
      />
    ),
    [
      originAlertProps,
      dynamicAlertProps,
      onClickConfirm,
      onClickCancel,
      isOpen,
    ],
  );

  useEffect(() => {
    if (isOpen) {
      showModal();
    } else {
      hideModal();
    }
  }, [isOpen, showModal, hideModal]);

  useEffect(() => {
    if (activeAlertCount > 0) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  });

  useEffect(() => {
    return () => {
      activeAlertCount = 0;
      document.body.style.overflow = "auto";
    };
  }, []);

  return { showAlert, hideAlert };
}
