/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * connect API
 * OpenAPI spec version: preview
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  BodyV1UserImagePut,
  ChangePasswordRequest,
  DeleteV1UserImage200,
  EmailAuthRequest,
  FindPasswordRequest,
  GetGoogleLoginResponse,
  GetV1User200,
  GetV1UserImage200,
  GoogleLoginCallbackRequest,
  LoginRequest,
  PostV1UserEmailAuth200,
  PostV1UserEmailAuthVerify200,
  PostV1UserFindPasswordAuth200,
  PostV1UserFindPasswordAuthVerify200,
  PostV1UserLogin200,
  PostV1UserLoginGoogleCallback200,
  PostV1UserLogout200,
  PostV1UserSignUp200,
  PostV1UserSignupGoogle200,
  PutV1User200,
  PutV1UserFindPassword200,
  PutV1UserImage200,
  PutV1UserPassword200,
  SignUpRequest,
  SignUpWithGoogleRequest,
  UpdateUserInfoRequest,
  VerifyEmailRequest,
} from "../connect-api.schemas";

/**
 * @summary 일반 로그인
 */
export const postV1UserLogin = (
  loginRequest: LoginRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostV1UserLogin200>> => {
  return axios.post(`/v1/user/login`, loginRequest, options);
};

export const getPostV1UserLoginMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserLogin>>,
    TError,
    { data: LoginRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postV1UserLogin>>,
  TError,
  { data: LoginRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postV1UserLogin>>,
    { data: LoginRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postV1UserLogin(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostV1UserLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof postV1UserLogin>>
>;
export type PostV1UserLoginMutationBody = LoginRequest;
export type PostV1UserLoginMutationError = AxiosError<unknown>;

/**
 * @summary 일반 로그인
 */
export const usePostV1UserLogin = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserLogin>>,
    TError,
    { data: LoginRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postV1UserLogin>>,
  TError,
  { data: LoginRequest },
  TContext
> => {
  const mutationOptions = getPostV1UserLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 구글 로그인
 */
export const postV1UserLoginGoogle = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetGoogleLoginResponse>> => {
  return axios.post(`/v1/user/login/google`, undefined, options);
};

export const getPostV1UserLoginGoogleMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserLoginGoogle>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postV1UserLoginGoogle>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postV1UserLoginGoogle>>,
    void
  > = () => {
    return postV1UserLoginGoogle(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostV1UserLoginGoogleMutationResult = NonNullable<
  Awaited<ReturnType<typeof postV1UserLoginGoogle>>
>;

export type PostV1UserLoginGoogleMutationError = AxiosError<unknown>;

/**
 * @summary 구글 로그인
 */
export const usePostV1UserLoginGoogle = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserLoginGoogle>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postV1UserLoginGoogle>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getPostV1UserLoginGoogleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 구글 로그인 콜백
 */
export const postV1UserLoginGoogleCallback = (
  googleLoginCallbackRequest: GoogleLoginCallbackRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostV1UserLoginGoogleCallback200>> => {
  return axios.post(
    `/v1/user/login/google/callback`,
    googleLoginCallbackRequest,
    options,
  );
};

export const getPostV1UserLoginGoogleCallbackMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserLoginGoogleCallback>>,
    TError,
    { data: GoogleLoginCallbackRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postV1UserLoginGoogleCallback>>,
  TError,
  { data: GoogleLoginCallbackRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postV1UserLoginGoogleCallback>>,
    { data: GoogleLoginCallbackRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postV1UserLoginGoogleCallback(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostV1UserLoginGoogleCallbackMutationResult = NonNullable<
  Awaited<ReturnType<typeof postV1UserLoginGoogleCallback>>
>;
export type PostV1UserLoginGoogleCallbackMutationBody =
  GoogleLoginCallbackRequest;
export type PostV1UserLoginGoogleCallbackMutationError = AxiosError<unknown>;

/**
 * @summary 구글 로그인 콜백
 */
export const usePostV1UserLoginGoogleCallback = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserLoginGoogleCallback>>,
    TError,
    { data: GoogleLoginCallbackRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postV1UserLoginGoogleCallback>>,
  TError,
  { data: GoogleLoginCallbackRequest },
  TContext
> => {
  const mutationOptions =
    getPostV1UserLoginGoogleCallbackMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 구글 최종 회원가입(약관 동의 설정)
 */
export const postV1UserSignupGoogle = (
  signUpWithGoogleRequest: SignUpWithGoogleRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostV1UserSignupGoogle200>> => {
  return axios.post(`/v1/user/signup/google`, signUpWithGoogleRequest, options);
};

export const getPostV1UserSignupGoogleMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserSignupGoogle>>,
    TError,
    { data: SignUpWithGoogleRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postV1UserSignupGoogle>>,
  TError,
  { data: SignUpWithGoogleRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postV1UserSignupGoogle>>,
    { data: SignUpWithGoogleRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postV1UserSignupGoogle(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostV1UserSignupGoogleMutationResult = NonNullable<
  Awaited<ReturnType<typeof postV1UserSignupGoogle>>
>;
export type PostV1UserSignupGoogleMutationBody = SignUpWithGoogleRequest;
export type PostV1UserSignupGoogleMutationError = AxiosError<unknown>;

/**
 * @summary 구글 최종 회원가입(약관 동의 설정)
 */
export const usePostV1UserSignupGoogle = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserSignupGoogle>>,
    TError,
    { data: SignUpWithGoogleRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postV1UserSignupGoogle>>,
  TError,
  { data: SignUpWithGoogleRequest },
  TContext
> => {
  const mutationOptions = getPostV1UserSignupGoogleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 로그인 확인
 */
export const getV1UserCheckLogin = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<boolean>> => {
  return axios.get(`/v1/user/check-login`, options);
};

export const getGetV1UserCheckLoginQueryKey = () => {
  return [`/v1/user/check-login`] as const;
};

export const getGetV1UserCheckLoginQueryOptions = <
  TData = Awaited<ReturnType<typeof getV1UserCheckLogin>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getV1UserCheckLogin>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetV1UserCheckLoginQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getV1UserCheckLogin>>
  > = ({ signal }) => getV1UserCheckLogin({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getV1UserCheckLogin>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetV1UserCheckLoginQueryResult = NonNullable<
  Awaited<ReturnType<typeof getV1UserCheckLogin>>
>;
export type GetV1UserCheckLoginQueryError = AxiosError<unknown>;

/**
 * @summary 로그인 확인
 */
export const useGetV1UserCheckLogin = <
  TData = Awaited<ReturnType<typeof getV1UserCheckLogin>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getV1UserCheckLogin>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetV1UserCheckLoginQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 로그아웃
 */
export const postV1UserLogout = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostV1UserLogout200>> => {
  return axios.post(`/v1/user/logout`, undefined, options);
};

export const getPostV1UserLogoutMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserLogout>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postV1UserLogout>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postV1UserLogout>>,
    void
  > = () => {
    return postV1UserLogout(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostV1UserLogoutMutationResult = NonNullable<
  Awaited<ReturnType<typeof postV1UserLogout>>
>;

export type PostV1UserLogoutMutationError = AxiosError<unknown>;

/**
 * @summary 로그아웃
 */
export const usePostV1UserLogout = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserLogout>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postV1UserLogout>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getPostV1UserLogoutMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 이메일 인증번호 발송(회원가입용)
 */
export const postV1UserEmailAuth = (
  emailAuthRequest: EmailAuthRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostV1UserEmailAuth200>> => {
  return axios.post(`/v1/user/email-auth`, emailAuthRequest, options);
};

export const getPostV1UserEmailAuthMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserEmailAuth>>,
    TError,
    { data: EmailAuthRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postV1UserEmailAuth>>,
  TError,
  { data: EmailAuthRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postV1UserEmailAuth>>,
    { data: EmailAuthRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postV1UserEmailAuth(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostV1UserEmailAuthMutationResult = NonNullable<
  Awaited<ReturnType<typeof postV1UserEmailAuth>>
>;
export type PostV1UserEmailAuthMutationBody = EmailAuthRequest;
export type PostV1UserEmailAuthMutationError = AxiosError<unknown>;

/**
 * @summary 이메일 인증번호 발송(회원가입용)
 */
export const usePostV1UserEmailAuth = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserEmailAuth>>,
    TError,
    { data: EmailAuthRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postV1UserEmailAuth>>,
  TError,
  { data: EmailAuthRequest },
  TContext
> => {
  const mutationOptions = getPostV1UserEmailAuthMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 이메일 인증번호 확인(회원가입용)
 */
export const postV1UserEmailAuthVerify = (
  verifyEmailRequest: VerifyEmailRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostV1UserEmailAuthVerify200>> => {
  return axios.post(`/v1/user/email-auth/verify`, verifyEmailRequest, options);
};

export const getPostV1UserEmailAuthVerifyMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserEmailAuthVerify>>,
    TError,
    { data: VerifyEmailRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postV1UserEmailAuthVerify>>,
  TError,
  { data: VerifyEmailRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postV1UserEmailAuthVerify>>,
    { data: VerifyEmailRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postV1UserEmailAuthVerify(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostV1UserEmailAuthVerifyMutationResult = NonNullable<
  Awaited<ReturnType<typeof postV1UserEmailAuthVerify>>
>;
export type PostV1UserEmailAuthVerifyMutationBody = VerifyEmailRequest;
export type PostV1UserEmailAuthVerifyMutationError = AxiosError<unknown>;

/**
 * @summary 이메일 인증번호 확인(회원가입용)
 */
export const usePostV1UserEmailAuthVerify = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserEmailAuthVerify>>,
    TError,
    { data: VerifyEmailRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postV1UserEmailAuthVerify>>,
  TError,
  { data: VerifyEmailRequest },
  TContext
> => {
  const mutationOptions = getPostV1UserEmailAuthVerifyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 회원가입
 */
export const postV1UserSignUp = (
  signUpRequest: SignUpRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostV1UserSignUp200>> => {
  return axios.post(`/v1/user/sign-up`, signUpRequest, options);
};

export const getPostV1UserSignUpMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserSignUp>>,
    TError,
    { data: SignUpRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postV1UserSignUp>>,
  TError,
  { data: SignUpRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postV1UserSignUp>>,
    { data: SignUpRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postV1UserSignUp(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostV1UserSignUpMutationResult = NonNullable<
  Awaited<ReturnType<typeof postV1UserSignUp>>
>;
export type PostV1UserSignUpMutationBody = SignUpRequest;
export type PostV1UserSignUpMutationError = AxiosError<unknown>;

/**
 * @summary 회원가입
 */
export const usePostV1UserSignUp = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserSignUp>>,
    TError,
    { data: SignUpRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postV1UserSignUp>>,
  TError,
  { data: SignUpRequest },
  TContext
> => {
  const mutationOptions = getPostV1UserSignUpMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 이메일 인증번호 발송(비밀번호 찾기용)
 */
export const postV1UserFindPasswordAuth = (
  emailAuthRequest: EmailAuthRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostV1UserFindPasswordAuth200>> => {
  return axios.post(`/v1/user/find-password-auth`, emailAuthRequest, options);
};

export const getPostV1UserFindPasswordAuthMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserFindPasswordAuth>>,
    TError,
    { data: EmailAuthRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postV1UserFindPasswordAuth>>,
  TError,
  { data: EmailAuthRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postV1UserFindPasswordAuth>>,
    { data: EmailAuthRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postV1UserFindPasswordAuth(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostV1UserFindPasswordAuthMutationResult = NonNullable<
  Awaited<ReturnType<typeof postV1UserFindPasswordAuth>>
>;
export type PostV1UserFindPasswordAuthMutationBody = EmailAuthRequest;
export type PostV1UserFindPasswordAuthMutationError = AxiosError<unknown>;

/**
 * @summary 이메일 인증번호 발송(비밀번호 찾기용)
 */
export const usePostV1UserFindPasswordAuth = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserFindPasswordAuth>>,
    TError,
    { data: EmailAuthRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postV1UserFindPasswordAuth>>,
  TError,
  { data: EmailAuthRequest },
  TContext
> => {
  const mutationOptions = getPostV1UserFindPasswordAuthMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 이메일 인증번호 확인(비밀번호 찾기용)
 */
export const postV1UserFindPasswordAuthVerify = (
  verifyEmailRequest: VerifyEmailRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostV1UserFindPasswordAuthVerify200>> => {
  return axios.post(
    `/v1/user/find-password-auth/verify`,
    verifyEmailRequest,
    options,
  );
};

export const getPostV1UserFindPasswordAuthVerifyMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserFindPasswordAuthVerify>>,
    TError,
    { data: VerifyEmailRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postV1UserFindPasswordAuthVerify>>,
  TError,
  { data: VerifyEmailRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postV1UserFindPasswordAuthVerify>>,
    { data: VerifyEmailRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postV1UserFindPasswordAuthVerify(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostV1UserFindPasswordAuthVerifyMutationResult = NonNullable<
  Awaited<ReturnType<typeof postV1UserFindPasswordAuthVerify>>
>;
export type PostV1UserFindPasswordAuthVerifyMutationBody = VerifyEmailRequest;
export type PostV1UserFindPasswordAuthVerifyMutationError = AxiosError<unknown>;

/**
 * @summary 이메일 인증번호 확인(비밀번호 찾기용)
 */
export const usePostV1UserFindPasswordAuthVerify = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postV1UserFindPasswordAuthVerify>>,
    TError,
    { data: VerifyEmailRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postV1UserFindPasswordAuthVerify>>,
  TError,
  { data: VerifyEmailRequest },
  TContext
> => {
  const mutationOptions =
    getPostV1UserFindPasswordAuthVerifyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 비밀번호 찾기
 */
export const putV1UserFindPassword = (
  findPasswordRequest: FindPasswordRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PutV1UserFindPassword200>> => {
  return axios.put(`/v1/user/find-password`, findPasswordRequest, options);
};

export const getPutV1UserFindPasswordMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putV1UserFindPassword>>,
    TError,
    { data: FindPasswordRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putV1UserFindPassword>>,
  TError,
  { data: FindPasswordRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putV1UserFindPassword>>,
    { data: FindPasswordRequest }
  > = (props) => {
    const { data } = props ?? {};

    return putV1UserFindPassword(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutV1UserFindPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof putV1UserFindPassword>>
>;
export type PutV1UserFindPasswordMutationBody = FindPasswordRequest;
export type PutV1UserFindPasswordMutationError = AxiosError<unknown>;

/**
 * @summary 비밀번호 찾기
 */
export const usePutV1UserFindPassword = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putV1UserFindPassword>>,
    TError,
    { data: FindPasswordRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putV1UserFindPassword>>,
  TError,
  { data: FindPasswordRequest },
  TContext
> => {
  const mutationOptions = getPutV1UserFindPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 비밀번호 변경
 */
export const putV1UserPassword = (
  changePasswordRequest: ChangePasswordRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PutV1UserPassword200>> => {
  return axios.put(`/v1/user/password`, changePasswordRequest, options);
};

export const getPutV1UserPasswordMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putV1UserPassword>>,
    TError,
    { data: ChangePasswordRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putV1UserPassword>>,
  TError,
  { data: ChangePasswordRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putV1UserPassword>>,
    { data: ChangePasswordRequest }
  > = (props) => {
    const { data } = props ?? {};

    return putV1UserPassword(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutV1UserPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof putV1UserPassword>>
>;
export type PutV1UserPasswordMutationBody = ChangePasswordRequest;
export type PutV1UserPasswordMutationError = AxiosError<unknown>;

/**
 * @summary 비밀번호 변경
 */
export const usePutV1UserPassword = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putV1UserPassword>>,
    TError,
    { data: ChangePasswordRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putV1UserPassword>>,
  TError,
  { data: ChangePasswordRequest },
  TContext
> => {
  const mutationOptions = getPutV1UserPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 회원 정보 조회
 */
export const getV1User = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetV1User200>> => {
  return axios.get(`/v1/user`, options);
};

export const getGetV1UserQueryKey = () => {
  return [`/v1/user`] as const;
};

export const getGetV1UserQueryOptions = <
  TData = Awaited<ReturnType<typeof getV1User>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getV1User>>, TError, TData>
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetV1UserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getV1User>>> = ({
    signal,
  }) => getV1User({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getV1User>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetV1UserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getV1User>>
>;
export type GetV1UserQueryError = AxiosError<unknown>;

/**
 * @summary 회원 정보 조회
 */
export const useGetV1User = <
  TData = Awaited<ReturnType<typeof getV1User>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getV1User>>, TError, TData>
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetV1UserQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 회원 정보 수정
 */
export const putV1User = (
  updateUserInfoRequest: UpdateUserInfoRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PutV1User200>> => {
  return axios.put(`/v1/user`, updateUserInfoRequest, options);
};

export const getPutV1UserMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putV1User>>,
    TError,
    { data: UpdateUserInfoRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putV1User>>,
  TError,
  { data: UpdateUserInfoRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putV1User>>,
    { data: UpdateUserInfoRequest }
  > = (props) => {
    const { data } = props ?? {};

    return putV1User(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutV1UserMutationResult = NonNullable<
  Awaited<ReturnType<typeof putV1User>>
>;
export type PutV1UserMutationBody = UpdateUserInfoRequest;
export type PutV1UserMutationError = AxiosError<unknown>;

/**
 * @summary 회원 정보 수정
 */
export const usePutV1User = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putV1User>>,
    TError,
    { data: UpdateUserInfoRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putV1User>>,
  TError,
  { data: UpdateUserInfoRequest },
  TContext
> => {
  const mutationOptions = getPutV1UserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 회원 이미지 조회
 */
export const getV1UserImage = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetV1UserImage200>> => {
  return axios.get(`/v1/user/image`, options);
};

export const getGetV1UserImageQueryKey = () => {
  return [`/v1/user/image`] as const;
};

export const getGetV1UserImageQueryOptions = <
  TData = Awaited<ReturnType<typeof getV1UserImage>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getV1UserImage>>, TError, TData>
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetV1UserImageQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getV1UserImage>>> = ({
    signal,
  }) => getV1UserImage({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getV1UserImage>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetV1UserImageQueryResult = NonNullable<
  Awaited<ReturnType<typeof getV1UserImage>>
>;
export type GetV1UserImageQueryError = AxiosError<unknown>;

/**
 * @summary 회원 이미지 조회
 */
export const useGetV1UserImage = <
  TData = Awaited<ReturnType<typeof getV1UserImage>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getV1UserImage>>, TError, TData>
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetV1UserImageQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 회원 이미지 수정
 */
export const putV1UserImage = (
  bodyV1UserImagePut: BodyV1UserImagePut,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PutV1UserImage200>> => {
  const formData = new FormData();
  formData.append("user_image", bodyV1UserImagePut.user_image);

  return axios.put(`/v1/user/image`, formData, options);
};

export const getPutV1UserImageMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putV1UserImage>>,
    TError,
    { data: BodyV1UserImagePut },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putV1UserImage>>,
  TError,
  { data: BodyV1UserImagePut },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putV1UserImage>>,
    { data: BodyV1UserImagePut }
  > = (props) => {
    const { data } = props ?? {};

    return putV1UserImage(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutV1UserImageMutationResult = NonNullable<
  Awaited<ReturnType<typeof putV1UserImage>>
>;
export type PutV1UserImageMutationBody = BodyV1UserImagePut;
export type PutV1UserImageMutationError = AxiosError<unknown>;

/**
 * @summary 회원 이미지 수정
 */
export const usePutV1UserImage = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putV1UserImage>>,
    TError,
    { data: BodyV1UserImagePut },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putV1UserImage>>,
  TError,
  { data: BodyV1UserImagePut },
  TContext
> => {
  const mutationOptions = getPutV1UserImageMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 회원 이미지 삭제
 */
export const deleteV1UserImage = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DeleteV1UserImage200>> => {
  return axios.delete(`/v1/user/image`, options);
};

export const getDeleteV1UserImageMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteV1UserImage>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteV1UserImage>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteV1UserImage>>,
    void
  > = () => {
    return deleteV1UserImage(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteV1UserImageMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteV1UserImage>>
>;

export type DeleteV1UserImageMutationError = AxiosError<unknown>;

/**
 * @summary 회원 이미지 삭제
 */
export const useDeleteV1UserImage = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteV1UserImage>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteV1UserImage>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getDeleteV1UserImageMutationOptions(options);

  return useMutation(mutationOptions);
};
